<template>
     <v-dialog v-model="open" persistent content-class="modal70">
     <div >
      <v-sheet 
       elevation="4"
      style="padding: 20px 20px 40px"
     >
      <!-- style="display:flex;flex-direction:column;padding: 20px 20px 40px;max-width:1240px" -->

      <div>
         <!-- cabecera titulo btn cerrar-->
      
      <div class="conflex" style="justify-content: space-between;align-items:center">
        <h2 style="color: grey">OBSERVACIONES</h2>
        <v-btn
          style="margin-left: 10px"
          v-bind="$cfg.btra_cfg.standard"
          title="Cerrar"
          @click="closeWindow()"
        >
          <v-icon>{{ "mdi-close" }}</v-icon>
        </v-btn>
      </div>

      <!-- fin cabecera  -->
      <div v-show="edicion" 
           class="columna" style="margin-left: 120px;margin-bottom:10px">
        <div style="display: flex;width:80%">

          <v-select
            v-model="dataC.select1"
            style="width: 80%"
            v-bind="$select"
            :items="select1"
            label="Seleccione tipo observacion"
            :disabled="!edicion"
            item-value="id"
            item-text="label"
            @change="select1Change"
          >
            <!-- v-model="schemaComponente.select1.id" -->
          </v-select>

          <v-select
            v-show="select2View"
            v-model="dataC.select2"
            style="width: 20%"
            v-bind="$select"
            :items="select2"
            label="motivo"
            :disabled="!edicion"
            item-value="id"
            item-text="label"
            @change="select2Change"
          >
          </v-select>
        </div>
   
        <v-textarea
          v-show="textView || dataC.texto!=''"
          v-model="dataC.texto"
          style="width: 850px"
          label="Introduzca observaciones aqui:"
          :disabled="!edicion"
          rows="6"
          :auto-focus="true"
          :no-resize="true"
          clearable
           
          clear-icon="mdi-close"
          filled
          @click:clear="clearText()"
        >
          <!-- v-model="schemaComponente.valueaux" -->
        </v-textarea>
      </div>

      <!--Area de mensajes -->
 
      <uiobs3Mensajes
        :arrMensajes="arrMensajes"
        :rolesMensajes="rolesMensajes"
        :rolesTitulo="rolesTitulo"
        :schemaComponente="schemaComponente"
        :motivos="motivos"
        :agrupar="agrupar">
      </uiobs3Mensajes>
   
       </div>
      </v-sheet>
         </div>
  </v-dialog>
  
</template>


<script>

  import uiobs3Mensajes from "@/components/uiobs3Mensajes.vue";
 
  export default {
    name: "obs3Motivos",
    components: {uiobs3Mensajes },
    props: {
      open: { type: Boolean, default: true },
      edicion: { type: Boolean, default: false},
      //_dataC: { type: [Object, Array] },
      schema: { type: [Object, Array] },
      schemaComponente: { type: [Object, Array] },
  
      motivos: { type: Boolean, default: false },
      agrupar: { type: Boolean, default: false },
      arrMensajes:{ type: [Object, Array], required: true },
      rolesMensajes:{ type: [Object, Array], required: true },
      rolesTitulo:{ type: [Object, Array], required: true },
    },

    data() {
      return {
        // variables de configuración
        Entorno: this.$cfg.ctrls.ctrlObs,
        dataC:{},
        select2View:false,
        textView:false,
        // variables del componente
  
        // textValue: this.schemaComponente.valueAux,
    
        select1: [
          {
            id: 1,
            label:
              "Observación que no requiere intervención del CTD",
            abrev: "",
            select2: false,
          },

          {
            id: 2,
            label:
              "* Observación que REQUIERE INTERVENCION DEL CTD  ",
            abrev: "(INTERV. CTD) ",
            select2: false,
          },
        
        ],
      
      // NO SE USA EN ESTA VERSION
        select2: [
          { id: 1, label: "Lapida" },
          { id: 2, label: "Nicho" },
          { id: 3, label: "Funeraria" },
          { id: 4, label: "Liquidación familia" },
          { id: 5, label: "Reclamación" },
          { id: 6, label: "Gestoria" },
          { id: 7, label: "Otros" },
        ],
      };
    },
    watch: {
      'schemaComponente.comp.data': {
        immediate: true,
        deep: true,

        handler () {
  
          if (this.schemaComponente[4].comp.data.texto!=undefined){
            this.dataC=JSON.parse(JSON.stringify(this.schemaComponente[4].comp.data))
          }
        }
      }
    },
    methods: {
      select1Change() {


        // checkeo  si muestro select2
        this.select2View =
          this.select1Value === 0
            ? false
            : this.select1[this.dataC.select1 - 1].select2;
          
        this.textShow();
      },
      select2Change() {
        this.textShow();
      },
      textShow() {
        // checkeo  si muestro entrada de texto
        
        if (
          this.dataC.select1 === 0 ||
          (this.select1[this.dataC.select1 - 1].select2 && this.dataC.select2 === 0)
        ) {
          this.textView = false;
          return;
        }
        this.textView = true;
      },
      clearText() {

        this.dataC.texto = "";
      },
      closeWindow() {

      
      if (!this.edicion || this.dataC.select1===0){
        this.salir(false)
        return
      }
      // chequear texto  nulo o con espacios
      this.dataC.texto=(this.dataC.texto??" ").trim();

      // falta motivo
      if(this.select1[this.dataC.select1 - 1].select2 && this.dataC.select2===0) {this.faltaMotivo();return}
      // falta texto
      if(this.dataC.texto=='') { this.faltaTexto();return}
      // cerrar/actualizar
      this.salir(true);
      },
      faltaMotivo(){
          this.$root.$alert.open("No ha seleccionado MOTIVO. La observacion no está completada ¿Desea salir y cancelar la observación ?","confirm",null,['SI', 'NO'])
          .then(r => {
            if (r==0) { this.salir(false); return }
            return;
          });
      },
      faltaTexto(){
          this.$root.$alert
          .open("No ha introducido ningún texto en las observaciones. La observación no está completada ¿Desea salir y cancelar la observación?","confirm", null,['SI', 'NO'])
          .then(r => {
            if (r==0) {this.salir(false); return }
            return;
          });
      },
    
      salir(update=true){
        // salir cuando !edicion / cancelar / sin datos
        if (!this.edicion || !update || this.dataC.select1===0){
            this.$emit("update", { obsComp: "uiobs3Motivos", data:{encabeza:'', texto:'',select1:0, select2:0}});
            return
        }
        // si el select1 no incluye select2 lo pongo a 0;
        if (!this.select1[this.dataC.select1 - 1].select2) this.dataC.select2=0;
        this.dataC.texto=(this.dataC.texto??" ").trim();
        // texto select1
        this.dataC.encabeza=this.select1[this.dataC.select1 - 1].abrev;
        // texto select2 si se muestra select2
        this.dataC.encabeza+=this.select1[this.dataC.select1 - 1].select2?" "+this.select2[this.dataC.select2- 1].label:"";
        this.$emit("update", { obsComp: "obs3Motivos", data:this.dataC})
      },
    },
  };
</script>
