

<template>
  <div>
    <!-- Botones -->
    <v-divider>prueba</v-divider>
    <div class="conflex">
      <div v-show="agrupar === true" style="display: flex">
        <div v-show="vistaGrupo == false" >
          <v-btn v-bind="$cfg.btn.busca_with_text" @click="vistaGrupo = true" rounded>
            DESAGRUPAR
          </v-btn>
        </div>

        <div v-show="vistaGrupo == true" >
          <v-btn v-bind="$cfg.btn.busca_with_text" @click="vistaGrupo = false" rounded>
            AGRUPAR 
          </v-btn>
        </div>
      </div>

      <div v-show="vistaGrupo == false" >
        <v-btn v-bind="$cfg.btn.busca_with_text" @click="ascendente=!ascendente" rounded>
          Invertir <v-icon right dark> mdi-swap-vertical </v-icon>
        </v-btn>
      </div>
    </div>

    <!-- Vista mensajes Sin dividir por rol -->
    <div v-show="vistaGrupo == false">
      <div
        class="conflex"
        style=" margin-top: 10px"
      ></div>

      <div v-for="(item, i) in mensajesView" :key="i">
        <div v-show="item.nameVista != ''">
          <div :class="item.color">
            {{ item.nameVista }}
          </div>
        </div>

        <div style="display: flex;">
          <div class="dia">
            <div
              v-if="i == 0 || mensajesView[i - 1].dia != item.dia"
              style="flex: 0 0 80px; text-align: right"
            >
              {{ item.dia }}
            </div>
            <div class="hora">{{ item.hora }}</div>
          </div>

          <div class="texto">
            {{ item.texto }}
          </div>
        </div>
      </div>
    </div>
    <!-- por rol -->
    <div v-show="vistaGrupo == true">
      <div
        v-for="(rol, keyName, index) in rolesMensajes"
        :key="index"
      >
        <div :class="keyName" style="font-size: 1.5rem">
          {{ rolesTitulo[keyName] }}
        </div>
        <v-divider></v-divider>
        <div v-for="(item, i) in rol" :key="i">
          <div v-show="i == 0 || rol[i - 1].name != item.name">
            <div :class="item.color">
              {{ item.name }}
            </div>
          </div>

          <div style="display: flex">
            <div class="dia">
              <div
                v-if="i == 0 || rol[i - 1].dia != item.dia"
                style="flex: 0 0 80px; text-align: right"
              >
                {{ item.dia }}
              </div>
              <div class="hora">{{ item.hora }}</div>
            </div>

            <div class="texto">
              {{ item.texto }}
            </div>
          </div>
        </div>
        <div style="margin-top: 2.5rem"></div>
      </div>

      <!--- --->
    </div>
  </div>
</template>
  



<script>
// let rolesTitulo={gestion:'CTD', apds:'APD', jz:'Jefe de Zona'}
export default {
  props: {
    agrupar: { type: Boolean, default: false },
    arrMensajes:{ type: [Object, Array], required: true },
    rolesMensajes:{ type: [Object, Array], required: true },
    rolesTitulo:{ type: [Object, Array], required: true },
  },

  data() {
    return {
 
      // variables de configuración
      Entorno: this.$cfg.ctrls.ctrlObs,
      // variables del componente
      ascendente: false,
      vistaGrupo: false,
    
    };
  },

  computed: {
    mensajesView() {
      // orden
      let _arrMensajes=this.arrMensajes;
      if (!this.ascendente) {
      _arrMensajes.sort((x, y) => y.orden - x.orden);
        
      } else {
        _arrMensajes.sort((x, y) => x.orden - y.orden);
      }
      // agrupa por nombre
      let nameAnterior = "";
     _arrMensajes.forEach((item) => {
        if (nameAnterior == item.name) {
          item.nameVista = "";
        } else {
          nameAnterior = item.name;
          item.nameVista = item.name;
        }
      });
        return _arrMensajes
    }
    },
 

}

</script>

<style  scoped>
.texto {
  /* text-align: justify; */
  white-space: pre-line;
  font-size: 1rem;
  padding-left: 10px;
}
.gestion {
  margin-top: 5px;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(109, 104, 41);
  padding: 0 0 0 130px;
}
.apds {
  margin-top: 5px;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(81, 136, 143);
  padding: 0 0 0 130px;
}
.jz {
  margin-top: 5px;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(128, 15, 0);
  padding: 0 0 0 130px;
}
.dia {
  display: flex;
  flex: 0 0 120px;
  /* padding: 0; */
  /* margin: 0; */
  font-weight: bold;
  font-size: 1rem;
  justify-content: flex-end;
  color: rgb(0, 0, 0);
}
.hora {
  color: rgb(127, 165, 187);
  padding: 0 0 0 5px;
}

/*
.circulo_ca24 {
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: rgb(79, 116, 79);
  margin: 0 5px 0 15px;
}

.circulo_apd {
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: rgb(38, 114, 124);
  margin: 0 5px 0 15px;
}
.circulo_jz {
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: rgb(128, 15, 0);
  margin: 0 5px 0 15px;
}
*/
</style>

